import * as React from 'react';

import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core';

import { User } from '../User';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 60px)',
    background: '#313131',
  },
  studentInformationContainer: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: 'calc(100vh - 60px)',
    overflow: 'auto',
    flex: 1,
  },
  bookingsInformationContainer: {
    width: '500px',
    height: 'calc(100vh - 60px)',
    overflow: 'auto',
  },
}));

interface UserPageProps {
  id: string;
}

const UserPage = ({ id }: UserPageProps) => {
  const styles = useStyles();
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <div className={styles.studentInformationContainer}>
          <User id={id} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default UserPage;
